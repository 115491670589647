<template>
<v-container>
    <div>
     <navbar title="افزودن آدرس"/>
     <v-main class="pt-15">
     <addAddress/>
     </v-main>
    </div>
</v-container>
</template>
<script>
import navbar from '@/components/navbar/appBar'
import addAddress from './components/forms/editAddress'
export default {
    components: {
      navbar,
      addAddress  
    }
}
</script>
